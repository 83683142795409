body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.toolbar {
  background: #11cfde;
}

.loading-container {
  text-align: center;
  margin-top: 10px;
}

.header-logo {
  height: 60px;
}

.crumbs {
  min-height: 0;
}

.crumbs a {
  font-size: 18px;
  text-decoration: none;
  margin-right: 15px;
}

.crumbs a:hover {
  text-decoration: underline;
  color: #FFFFFF;
}

.crumbs a p {
  color: #FFFFFF;
  font-family: "Trebuchet MS", Helvetica, sans-serif;
  margin-top: -3px;
}

.error {
  width: 100%;
  margin: 40px 0;
  text-align: center;
  color: #EB5858;
}

.not-found {
  text-align: center;
}

.active {
  padding: 2%;
}

.menu {
  font-size: 16px;
  color: #5caee8;
}

.menu-hover:hover {
  border: 0 !important;
}

.form-input-label {
  width: 100%;
}

.form-buttons {
  width: 96%;
}

.vendor-credentials-input {
  width:100px;
  margin:20px;
}

.vendor-credentials-select {
  width:300px;
  margin:20px;
}

.vendor-phone-select {
  width:200px;
  margin:20px;
}

.slider-form {
  width: 10%;
  margin:20px;
}

.provider-row {
  align-items: center;
  padding: 10px;
}

.vendor-credentials-button {
  width: 15%;
  height: 50px;
}

.phone-credentials-input {
  width:150px;
  margin-bottom:-8px !important; /* csslint allow: known-properties, important */
}

.phone-credentials-select {
  width:250px;
}

.phone-provider-row {
 align-items: center;
 padding: 5px;
}

.dialog {
  min-width: 400px !important; /* csslint allow: known-properties, important */
}

.search-dialog {
  width: 150px;
  margin-top: -10px !important; /* csslint allow: known-properties, important */
}

.search-dialog-small {
  width: 80px;
  margin-top: -10px !important; /* csslint allow: known-properties, important */
}

.select-dialog {
  width: 250px;
}

.dialog-text {
  font-size: 15px !important; /* csslint allow: known-properties, important */
  font-weight: normal !important; /* csslint allow: known-properties, important */
}

.filter-input {
  width: 300px;
  margin-right: 50px !important; /* csslint allow: known-properties, important */
}

.test-integration-container {
  margin: 50px;
}

.sms {
  width: 300px;
}

.payload {
  width: 500px;
}

.form-input-label-auth {
  width: 100%;
  margin-top: 23px !important; /* csslint allow: known-properties, important */
}

.email {
  width: 400px;
}

.e-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #34495e;
}

.l-width--full {
  width: 100%;
}

.e-tip {
  margin: 0;
  padding: 0 24px;
  color: #34495e;
  font-size: 12px;
}
